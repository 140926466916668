.component-bg-cover {
    position: fixed;
    background-color: $primary25t;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
.noScroll {
    position: fixed;
    left: $menuWidth + $contentLeftRightPadding;
    right: $contentLeftRightPadding;
}
.flip-list-move {
    transition: transform 0.5s;
}
.vdr.active::before {
    outline: none !important;
}
.col-xl, .col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg, .col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md, .col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm, .col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col, .col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
    padding-right: 10px;
    padding-left: 10px;
}
.custom-switch {
    label {
        cursor: pointer;
        font-size: 14px!important;
        // padding-top: 4px
    }
    &.large-label {
        label {
            font-size: 18px!important;
            padding-top: 2px
        }
    }
}

.modal-email-details {
    .info {
        display: flex;
        margin-bottom: 5px;
        .label {
            width: 20%;
            margin-right: 10px;
            color: #5e5e5e;
        }
        .value {
            width: 80%;
            ul {
                &.files-list {
                    display: flex;
                    flex-direction: column;
                }
                li {
                    width: fit-content;
                }
            }
        }
    }
}

.noScroll {
    position: fixed;
    left: $menuWidth + $contentLeftRightPadding;
    right: $contentLeftRightPadding;
}