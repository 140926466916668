.pt-25 {
    padding-top: 25px;
}
.mt-25 {
    margin-top: 25px;
}
.w-25-px {
    width: 25px;
}
.w-50-px {
    width: 50px;
}
.w-75-px {
    width: 75px;
}
.w-85-px {
    width: 85px;
}
.w-100-px {
    width: 100px;
}
.w-125-px {
    width: 125px;
}
.w-150-px {
    width: 150px;
}
.w-175-px {
    width: 175px;
}
.w-200-px {
    width: 200px;
}
.w-225-px {
    width: 225px;
}
.w-250-px {
    width: 250px;
}
.w-275-px {
    width: 275px;
}
.w-300-px {
    width: 300px;
}