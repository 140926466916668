.footer {
    padding: $contentPadding;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #fff;
    font-size: 14px;
    color: #717477;
    box-shadow: 0 1px 3px 0 rgba(0,0,0,.1),0 1px 2px 0 rgba(0,0,0,.06);
    border-top: 1px solid $hrColor;
    > a {
        font-size: 14px;
        color: $primary;
    }
}