.pagination.menu {
    margin: 10px 0px !important;
}
.vuetable-pagination {
    padding-top: 15px;
}
.table-wrap {
    position: relative;
    box-shadow: 0 4px 20px 1px rgba(0,0,0,.06),0 1px 4px rgba(0,0,0,.08);
    padding: 10px 20px 10px;
}
.table th, .table td {
    vertical-align: unset;
}
.VTLoader {
    position: absolute;
    text-align: center;
    color: #fff;
    width: 100%;
    height: 100%;
    background: $primary25t;
    z-index: 1000;
    margin: -9px -19px 0px;
}
.VTLoader > div {
    position: absolute;
    top: 25%;
    width: 100%;
}
.vuetable thead th {
    border-top: none;
    color: #606266;

    &:hover {
        color: $primary !important;
    }
}

.vuetable .sorted-desc.sortable, .vuetable .sorted-asc.sortable, .vuetable i.sort-icon, .vuetable th.sortable:hover {
    color: $primary !important;
}
.dt-action-col {
    width: 125px !important;
}
.dt-action-btns {
    text-align: center;
    padding-left: 10px;

    .btn {
        // padding: 0.25rem 0rem !important;
        font-size: 14px !important;
    }
}
.ui.selectable.table tbody tr:hover, .ui.table tbody tr td.selectable:hover {
    background: rgba(0,0,0,.03) !important;
}
.table-loader {
    position: absolute;
    text-align: center;
    color: $panelLoaderColor;
    width: 100%;
    height: 100%;
    background: $panelLoaderBg;
    z-index: 105;
    margin: -9px -19px 0px;

    div {
        position: absolute;
        top: 25%;
        width: 100%;
    }
}
.chart-loader div { top: 40%; }
.table th .enhancedCheck label , 
.table td .enhancedCheck label {
    margin-bottom: 0px !important;
}
.v-pagination {
    padding: 0 !important;

    a {
        position: relative;
        text-decoration: none;
        padding: 13px 16px;
        cursor: pointer;
        font-size: 15px;
        color: #777;

        &:hover {
            background: rgba(0,0,0,.03);
        }

        &.disabled {
            display: none;
        }

        &.active {
            background-color: rgba(0,0,0,.05);
        }
    }
}
.vuetable-pagination-info {
    padding-top: 1rem !important;
    color: #777;
}